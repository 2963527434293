<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="d-flex">
    <SubMenu :nameModule="nameModule" :subMenu="subMenu" />

    <!-- Seccion para cargar el contenido de la navegación del modulo-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>
  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import SubMenu from '@/components/SubMenu.vue';
import { Role } from "../../../router/role.js";

export default {
  name: 'Analytics',
  components: { SubMenu },
  data: () => ({
    tab: null,
    nameModule: "Analítica de Datos",
    userRoles: {},
    roles: Role,
    subMenu: [
      // {
      //   name: "Planeación",
      //   icon: "legend_toggle",
      //   rol: Role.Analitica_Planeacion,
      //   link: { name: 'modules.analytics.planning' }
      // },
      // {
      //   name: "Comercial",
      //   icon: "monetization_on",
      //   rol: Role.Analitica_Comercial,
      //   link: { name: 'modules.analytics.trade' }
      // },
      // {
      //   name: "Compras",
      //   icon: "add_shopping_cart",
      //   rol: Role.Analitica_Compras,
      //   link: { name: 'modules.analytics.shopping' }
      // },
      // {
      //   name: "Logística",
      //   icon: "local_shipping",
      //   rol: Role.Analitica_Logistica,
      //   link: { name: 'modules.analytics.logistics' }
      // },
      // {
      //   name: "Servicios Farmacéuticos",
      //   icon: "local_hospital",
      //   rol: Role.Analitica_ServiciosFarmaceuticos,
      //   link: { name: 'modules.analytics.pharmaceuticalService' }
      // },
      // {
      //   name: "Facturación",
      //   icon: "request_quote",
      //   rol: Role.Analitica_Facturacion,
      //   link: { name: 'modules.analytics.billing' }
      // },
      {
        name: "Tableros",
        icon: "dashboard",
        rol: Role.Analitica_Tableros,
        link: { name: 'modules.analytics.board' }
      },
    ],

  }),
  computed: {
    ...mapState(["auth"]),
  },
  created() {
    this.userRoles = this.auth.roles;
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de styles                                                      ###### -->
<!-- #################################################################################### -->
<style scoped>

</style>